import Vue from 'vue';
import Vuex from 'vuex';
// Modules
import modules from './modules/'
// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules,
  actions: {
    reset({commit}) {
      // modüller üzerindeki tüm "state"leri resetlemek için
      // Örnek => this.$store.dispatch('reset');
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`);
      })
    }
  },
});
