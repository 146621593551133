<template>
  <v-app>
    <v-container class="fill-height" style="min-width:320px;max-width:700px"
      ><v-card class="col-12" elevation="12">
        <v-alert color="blue" dark class="text-center">
          <h2>Kayıt Onay</h2>
        </v-alert>
        <v-row justify="center" style="padding:10px;">
          <v-col cols="12">
            <div class="text-center font-weight-bold">
              <h3>Kişisel Bilgiler</h3>
            </div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-chip color="primary" label>
              <v-icon left> mdi-account-arrow-right-outline </v-icon>
              {{ userInfo.kullaniciAdi }}
            </v-chip>
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-chip color="primary" label>
              <v-icon left> mdi-account-circle-outline </v-icon>
              {{ userInfo.ad }}
            </v-chip>
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-chip color="primary" label>
              <v-icon left> mdi-email-outline </v-icon>
              {{ userInfo.eposta }}
            </v-chip>
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-chip color="primary" label>
              <v-icon left> mdi-cellphone </v-icon>
              {{ userInfo.telefon }}
            </v-chip>
            <v-text-field
              type="text"
              v-model="onayKodu"
              outlined
              dense
              placeholder="Onay Kodu"
              class="mt-4"
            ></v-text-field>
            <v-btn
              :disabled="onayKodu.length <= 3"
              :max="4"
              @click="submit"
              color="green"
              dark
              class="float-left"
              >Onayla
            </v-btn>
            <v-spacer></v-spacer
            ><v-btn
              name="code"
              color="red darken-2"
              v-if="kodYok"
              dark
              class="float-right"
              @click="tekrarGonder"
              >Kod Gelmedi</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'onay',
  data() {
    return {
      onayKodu: '',
      yonlendir: '',
      kodYok: false,
      yonlendirmeUrl: 'https://testokul.xysanaliz.com',
    };
  },
  created() {
    this.$vuetify.theme.isDark = false;
    this.$store.dispatch('REGISTER_INFO', this.$route.params.mongo);
    this.kodGelmedi();
  },
  mounted() {
    setTimeout(() => {
      this.Kontrol();
    }, 1500);
  },
  methods: {
    Kontrol() {
      if (this.userInfo.telefonOnayDurumu) {
        this.$swal({
          icon: 'info',
          title: 'Kayıtlı Telefon',
          text: 'Numaranız daha önce onaylanmıştır.',
          allowOutsideClick: true,
          confirmButtonText: 'Kapat',
        }).then(() => {
          window.location = this.yonlendirmeUrl;
        });
      }
    },
    async submit() {
      await this.$store
        .dispatch('REGISTER_SUBMIT', {
          mongoId: this.$route.params.mongo,
          onayKodu: this.onayKodu,
        })
        .then(() => {
          if (this.userSubmit.status == 400) {
            if (
              this.userSubmit.data ==
              'Telefonunuz onaylama işlemi daha önceden gerçekleşmiştir.'
            ) {
              this.$swal({
                icon: 'info',
                title: 'Kayıtlı Telefon',
                text: this.userSubmit.data,
                allowOutsideClick: false,
                confirmButtonText: 'Kapat',
              }).then(() => {
                window.location = this.yonlendirmeUrl;
              });
            } else {
              this.$swal({
                icon: 'error',
                title: 'Hata Durumu',
                text: this.userSubmit.data,
                allowOutsideClick: false,
                confirmButtonText: 'Kapat',
              });
            }
          } else if (this.userSubmit.status == 200) {
            this.$swal({
              icon: 'success',
              title: 'İşlem Başarılı',
              text: this.userSubmit.data,
              allowOutsideClick: false,
              confirmButtonText: 'Yönlendir',
            }).then(() => {
              window.location = this.yonlendirmeUrl;
            });
          }
        });
    },
    async tekrarGonder() {
      await this.$store
        .dispatch('FORGOT_RECHECK', {
          mongoId: this.$route.params.mongo,
        })
        .then(() => {
          if (
            this.forgotReChek.status == 400 ||
            this.forgotReChek.status == 500
          ) {
            this.$swal({
              icon: 'warning',
              title: 'Dikkat !',
              text: this.forgotReChek.data,
              confirmButtonText: 'Kapat',
            });
          } else {
            this.$swal({
              icon: 'success',
              title: 'İşlem Gönderildi',
              text: 'Telefonunuza yeni kod gönderilmiştir.',
              confirmButtonText: 'Kapat',
            });
          }
        });
    },
    kodGelmedi() {
      setTimeout(() => {
        this.kodYok = true;
      }, 15000);
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userSubmit() {
      return this.$store.getters.registerSubmit;
    },
    forgotReChek() {
      return this.$store.getters.forgotReChek;
    },
  },
};
</script>
